@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root{
  --color-white: #fff;
  --color-black: #000;
  --color-background: #27221e;
  --color-secondary: #000;
  --color-primary-100: #F1BA23;
  --color-primary-200: #F9C349;
  --color-disabled: gray;

  --color-takealot: #08588c;
  --color-amazon: #ff9900;

  --font-size-hero: 30px;
  --font-size-title: 32px;
  --font-size-body: 24px;
  --font-size-alt: 16px;
  --font-size-card: 21px;
}

*{
  font-family: 'Montserrat', sans-serif;
}

.navbar {
  background: var(--color-background);
  color: var(--color-white);
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  height: 111px;
}
.link-btn {
  color: var(--color-black);
  background: var(--color-primary-100);
  border: 1px solid var(--color-background);
  width: 180px;
  height: 45px;
  padding: 10px;
  border-radius: 5px;
  font-size: var(--font-size-alt);
  text-decoration: none;
  margin: 0 20px;
}
.link-btn:hover {
  color: var(--color-primary-100);
  background: var(--color-background);
  border: 1px solid var(--color-primary-100);
  transition: 200ms;
}
.link-btn-active {
  color: var(--color-primary-100);
  background: var(--color-background);
  border: 1px solid var(--color-primary-100);
  width: 180px;
  height: 45px;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  margin: 0 20px;
}
.site-identity {
  display: flex;
  align-items: center;
}
.site-identity img {
  margin: 10px;
}
.accent {
  color: var(--color-primary-100);
}
.store-links {
  margin: 10px 30px;
  display: flex;
  align-items: center;
}
.store-links img{
  margin: 15px;
}
.amazon-logo {
  margin-bottom: -16px;
}
.amazon-logo:hover, .takealot-logo:hover{
  margin: 0 0 20px 0;
  transition: margin 300ms ease-in-out;
}
.hero-container {
  margin-top: 111px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--color-background);
  background-repeat: no-repeat;
  background-image: url("./assets/HeroBG.png");
  background-size: cover;
  background-position: bottom; 
  color: var(--color-white);
  height: 500px;
  width: 100%;
}

.hero-title {
  font-size: var(--font-size-hero);
  width: 800px;
  margin: 0 0 0 500px;
}

.hero-details {
  display: flex;
  justify-content: space-evenly;
  height: 470px;
}

.book-about {
  width: 600px;
  font-size: var(--font-size-body);
  margin: 20px;
}

.book-cover-container {
  margin-top: -300px;
  animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: -350px;
}
.book-cover-container:hover {
  animation: normal;
}
.book-cover-container img {
  height: 100%;
  width: 100%;
}
.flip-card {
  display: block;
  background-color: transparent;
  width: 100%;
  height: 624px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.order-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 30px;
}

.order-takealot-btn {
  width: 434px;
  height: 50px;
  background: var(--color-takealot);
  color: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  font-size: var(--font-size-body);
}
.order-takealot-btn:hover {
  color: var(--color-black);
  background: var(--color-primary-100);
  border: 1px solid var(--color-black);
  transition: 200ms;
}
.download-pdf-btn {
  width: 434px;
  height: 50px;
  background: var(--color-primary-100);
  color: var(--color-black);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  font-size: var(--font-size-body);
}
.download-pdf-btn:hover{
  background: var(--color-background);
  color: var(--color-white);
  transition: 200ms;
}
.order-amazon-btn {
  width: 434px;
  height: 50px;
  background: var(--color-secondary);
  color: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  font-size: var(--font-size-body);
}
.order-amazon-btn:hover {
  color: var(--color-black);
  background: var(--color-amazon);
  border: 1px solid var(--color-black);
  transition: 200ms;
}
.card-container {
  background: var(--color-primary-100);
  margin-top: 180px;
  padding: 40px;
}

.card-row {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--color-background);
  color: var(--color-white);
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  width: 300px;
  height: 100px;
}
.card img {
  margin: 0 15px;
  height: 60px;
  fill: var(--color-primary-100);
}
.card h4 {
  font-size: var(--font-size-card);
}
.title {
  font-size: var(--font-size-title);
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.title-alt {
  font-size: var(--font-size-title);
  font-weight: bold;
  color: var(--color-white);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.author-container {
  display: flex;
}
.author-info{
  font-size: var(--font-size-body);
  display: flex;
  align-items: center;
  padding: 40px;
}
.author-image{
  background-image: url("./assets/AuthorBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.author-image img {
  width: 350px;
  height: 350px;
}
.author-socials svg{
  width: 30px;
  height: 30px;
  background-color: var(--color-white);
  fill: var(--color-background);
  border: solid 1px var(--color-background);
  border-radius: 5px;
}
.author-socials svg:hover {
  background-color: var(--color-background);
  fill: var(--color-primary-100);
  transition: 200ms;
}
.cta-container {
  padding: 40px;
  background-image: url("./assets/CTABG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left; 
}
.logo-container {
  display: flex;
  justify-content: space-around;
}
.logo-container img{
  height: 110px;
}
.contact-container {
  display: flex;
  justify-content: space-around;
}
.contact-details {
  padding: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  font-size: var(--font-size-body);
}
.contact-logo-container {
  margin-top: 50px;
  display: flex;
  gap: 50px;
}
.contact-form {
  background: var(--color-background);
  width: 50%;
}
.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: var(--color-primary-100);
  gap: 10px;
}
.contact-form {
  padding: 30px;
}
.contact-form input[type=text], .contact-form input[type=email], .contact-form textarea, .contact-form select  {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-background);
  font-size: var(--font-size-alt);
}
.contact-form select {
  overflow-y: auto;
}
.contact-form textarea {
  height: 150px;
  resize: none;
  width: 97% !important;
}
.contact-form input[type=submit] {
  color: var(--color-background);
  background: var(--color-primary-100);
  border: 1px solid var(--color-background);
  width: 180px;
  height: 45px;
  padding: 10px;
  border-radius: 5px;
  font-size: var(--font-size-alt);
}
.contact-form input:disabled, .contact-form input:hover:disabled{
  background: var(--color-disabled);
}
.contact-form input[type=submit]:hover {
  background: var(--color-background);
  color: var(--color-primary-100);
  border-color: var(--color-primary-100);
  transition: 200ms;
}
.footer {
  background: var(--color-background);
  color: var(--color-white);
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
}
.coming-soon-container {
  background-image: url("./assets/CTABG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left; 
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-coming-soon {
  color: var(--color-white);
  text-align: center;
  font-size: 40px;
}

@media screen and (max-width: 970px) {
    .navbar {
      display: inline-block;
      height: fit-content;
      position: unset;
      z-index: none;
    }
    .site-identity {
      justify-content: center;
    }
    .store-links {
      justify-content: space-around;
    }
    .nav-links {
      display: contents;
    }
    .takealot-logo:hover {
      margin: 0;
    }
    .amazon-logo:hover {
      margin-bottom: -16px;
    }
    .hero-container {
      margin: unset;
    }
    .hero-title {
      font-size: 20px;
      width: auto;
      margin: 0 0 0 50%;
    }
    .hero-details {
      display: inline;
      height: unset;
    }
    .book-cover-container {
      margin-top: unset;
    }
    .book-cover-container img {
      width: 400px;
    }
    .book-about {
      width: unset;
      padding: 60px;
      background: var(--color-background);
      color: var(--color-white);
      margin: 20px 0 0 0;
    }
    .card-container {
      margin: unset;
    }
    .card-row {
      flex-wrap: wrap;
    }
    .card {
      max-width: 220px;
    }
    .card img {
      margin: 0 15px;
      height: 50px;
    }
    .card h4 {
      font-size: var(--font-size-alt);
    }
    .author-container {
      display: flexbox;
      flex-direction: column-reverse;
    }
    .author-info {
      padding: 0 40px 40px 40px;
    }
    .author-image {
      background: none;
      padding: 50px 50px 15px 50px;
    }
    .author-image img {
      width: 250px;
      height: 250px;
    }
    .logo-container img {
      width: 90%;
    }
    .contact-container {
      height: unset;
      flex-direction: column;
    }
    .contact-details {
      width: unset;
    }
    .contact-form {
      width: unset;
    }
    .footer {
      display: inline-block;
      height: fit-content;
      position: unset;
    }
    .title-coming-soon {
      font-size: 30px;
      padding: 30px;
    }
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}